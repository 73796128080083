import React from "react";
import PostData from "../../../packs_helper/scripts/PostData";

class MyInvoicesCore extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      invoices: [],
      current_page: 1,
      invoice_count: 0,
			activeTab: "order-invoices",
    };

    this.ActivateTab = this.ActivateTab.bind(this);
    this.GetData = this.GetData.bind(this);
    this.ChangePage = this.ChangePage.bind(this);
  }

  componentDidMount() {
    this.GetData(1);
  }


  ChangePage(current_page) {
    this.setState({ current_page });
    this.GetData(current_page);
  }

  ActivateTab(activeTab) {
    this.setState({ activeTab }, () => {
      this.GetData(1);
    });
	}

  GetData(page){
    let kind = 'order';
    if (this.state.activeTab === "commission-invoices") {
			kind = 'commission';
		}
    PostData("/faturalarim", {
      page,
      kind
    })
    .then((response) => {
      if(response.data.length){
        
      }
      this.setState({
        invoices: response.data,
        invoice_count: response.total_count
      });
    })
    .catch((err) => {
    });
  }
}


export default MyInvoicesCore;
