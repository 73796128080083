import Settings from "./Settings";
import MyOrders from "./MyOrders";
import MySales from "./MySales";
import MyItems from "./MyItems";
import MyMessages from "./MyMessages";
import MyComments from "./MyComments";
import PostAnAd from "./PostAnAd";
import MyCashOuts from "./MyCashOuts";
import CashOut from "./CashOut";
import MyPayments from "./MyPayments";
import CurrentAssets from "./CurrentAssets";
import Notifications from "./Notifications";
import SupportTickets from "./SupportTickets";
import MyStorePanel from "./MyStorePanel";
import MyInvoices from "./MyInvoices";

const profileButtons = props => ([
	{
		name: 'Settings',
		label: 'Ayarlar',
		icon: 'settings',
  	pathname: '/profilim',
  	component: Settings,
	},
	{
		name: 'MyOrders',
		label: 'Siparişlerim',
		icon: 'my-orders',
  	pathname: '/siparislerim',
  	component: MyOrders,
	},
	{
		name: 'MySales',
		label: 'Satış İşlemlerim',
		icon: 'my-sales',
  	pathname: '/satislarim',
  	component: MySales,
	},
	{
		name: 'MyMessages',
		label: 'Mesajlarım',
		icon: 'my-messages',
		pathname: '/mesajlarim',
		alert: props.unread_message_count > 0,
		component: MyMessages,
	},
	{
		name: 'MyStorePanel',
		label: 'Mağazam',
		icon: 'store',
		pathname: '/magaza-panelim',
		component: MyStorePanel,
	},
	{
		name: 'MyItems',
		label: 'İlanlarım',
		icon: 'my-items',
  	pathname: '/ilanlarim',
  	component: MyItems,
	},
	{
		name: 'PostAnAd',
		label: 'İlan Ekle',
		icon: 'post-an-ad',
  	pathname: '/ilan-ekle',
		user: props.user,
  	component: PostAnAd,
	},
	{
		name: 'MyComments',
		label: 'Yorumlarım',
		icon: 'my-comments',
		pathname: '/yorumlarim',
		component: MyComments,
	},
	{
		name: 'MyCashOuts',
		label: 'Nakit Çekimlerim',
		icon: 'my-cash-outs',
  	pathname: '/odeme-taleplerim',
  	component: MyCashOuts,
	},
	{
		name: 'CashOut',
		label: 'Nakite Çevir',
		icon: 'cash-out',
  	pathname: '/banka-hesaplarim',
  	component: CashOut,
	},
	{
		name: 'MyPayments',
		label: 'Ödemelerim',
		icon: 'my-payments',
  	pathname: '/odemelerim',
  	component: MyPayments,
	},
	{
		name: 'CurrentAssets',
		label: 'Cari',
		icon: 'current',
  	pathname: '/hareketler',
  	component: CurrentAssets,
	},
	{
		name: 'SupportTickets',
		label: 'Destek Taleplerim',
		icon: 'support',
  	pathname: '/destek-taleplerim',
  	component: SupportTickets,
	},
	{
		name: 'Notifications',
		label: 'Bildirimler',
		icon: 'notifications',
  	pathname: '/bildirimlerim',
		alert: props.unread_notification_count > 0,
  	component: Notifications,
	},
	{
		name: 'Invoices',
		label: 'Faturalarım',
		icon: 'copy',
  	pathname: '/faturalarim',
  	component: MyInvoices,
	},
]);

export default profileButtons;