import React from "react";
import Currency from "../../../packs_helper/scripts/Currency";
import DateFormat from "../../../packs_helper/scripts/DateFormat";
import Paginate from "../../Common/Paginate";
import MyInvoicesCore from "./MyInvoicesCore";
import Switcher from "../../Common/Switcher";

class MobileRenderer extends MyInvoicesCore {
	render() {
		const { invoices, invoice_count, page, activeTab } = this.state;
		return (
      <div>
        <Switcher
            buttons={[
              {
                id: "order-invoices",
                text: "Sipariş Faturalarım",
              },
              {
                id: "commission-invoices",
                text: "Komisyon Faturalarım",
              },
            ]}
            onChange={this.ActivateTab}
            active={activeTab}
          />
        <div className="my-invoices-container grid-1">
            {invoices.map((invoice) => (
              <a href={`/fatura?id=${invoice.invoice_id}`} target="_blank" key={invoice.invoice_id}>
              <div className="invoice-row" >
                <div className="invoice-row-content">
                <div class="status-icon status-icon-jungle"><svg class="icon"><use xlinkHref="#gs-icon-user-panel-approved"></use></svg></div>
                  <strong>
                    #{invoice.invoice_id}
                  </strong>
                  <div><strong className="text-red">{DateFormat(invoice.invoice_date, '%d/%m/%Y')}</strong></div>
                  <div><strong className="text-green">{Currency(invoice.invoice_total)} ₺</strong></div>
                </div>
                <div>
                  <button class="btn"><svg class="icon"><use xlinkHref="#gs-icon-user-panel-caret-down"></use></svg></button>
                </div>
              </div>
              </a>
            ))}
        </div>
        <div className="my-invoices-container footer">
          {invoice_count > 0 ? (
            <div className="notifications-footer">
              <Paginate
                onChange={this.ChangePage}
                total_count={invoice_count}
                current_page={this.state.current_page}
                per_page={15}
              />
            </div>
          ) : (
            <div className="alert">Hiç faturanız bulunmuyor!</div>
          )}
        </div>
      </div>
		);
	}
}

export default MobileRenderer;
