import React from "react";
import MyOrdersCore from "./MyOrdersCore";

import MyOrderBox, { StatusIcon } from "./MyOrderBox";
import OrderDetailButtons from "./OrderDetailButtons";
import Comment from "./Comment";
import ListKeys from "./ListKeys";
import Paginate from "../../Common/Paginate";
import ImageWithFallback from "../../Common/ImageWithFallback";
import DateFormat from "../../../packs_helper/scripts/DateFormat";
import Currency from "../../../packs_helper/scripts/Currency";
import Switcher from "../../Common/Switcher";
import PopUp from "../../Common/PopUp";

const statuses = [
{
	id: "",
	text: "Tümü",
},
{
	id: "1",
	text: "Onaylandı",
},
{
	id: "0",
	text: "Bekliyor",
},
{
	id: "2",
	text: "İptal Edildi",
},
];

const statusTexts = {
	approved: "Tamamlandı",
	pending: "Beklemede",
	rejected: "Reddedildi",
};

class MobileRenderer extends MyOrdersCore{
	render() {
		const { order_details, order_count, activeTab, comment_popup, current_order } = this.state;
		return (
			<div className="my-orders-container grid-1">

				<Switcher
					buttons={statuses}
					onChange={this.ActivateTab}
					active={activeTab}
				/>

				<form action="" className="section-content filter-top">
					<div className="input-with-icon search-box">
						<button className="icon">
							<svg>
								<use xlinkHref="#gs-icon-layout-search"></use>
							</svg>
						</button>
						<input 
							type="search" 
							placeholder="Sipariş ID veya ilan başlığıyla ara..." 
							name="q" 
							value={this.state.q} 
							onChange={(e) => { 
								this.setState({q: e.target.value}) 
							}} 
						/>
					</div>
				</form>

				{order_details.length ? (
					<React.Fragment>
				{order_details.map((order_detail) => (
					<MyOrderBox
						id={order_detail.id}
						name={order_detail.name}
						status={order_detail.status}
						GetDetail={this.GetDetail}
						ref={(ref) => (order_detail.ref = ref)}
						key={order_detail.id}
						BoxOpen={this.BoxOpen}
						show={this.state.activeBox === order_detail.id}
					>
						<div className="order-detail">
						{this.props.mobile}
							<ImageWithFallback
								className="order-detail-image"
								src={order_detail.content.image && order_detail.content.image.replace('-thumb.', '.')}
								mobile={this.props.mobile}
							/>

							<div className="order-detail-body">
								<div className="order-detail-body-left">
									<div>
										<label>Tarih:</label>
										<strong>{DateFormat(order_detail.created_at)}</strong>
									</div>
									<div>
										<label>Satıcı:</label>
										<strong>
											<a href={'magaza/' + order_detail.store_slug}>
												{order_detail.store_name}
											</a>
										</strong>
									</div>
									<div>
										<label>Birim Fiyat:</label>
										<strong>{Currency(order_detail.amount)} ₺</strong>
									</div>
									<div>
										<label>Adet:</label>
										<strong>{Currency(order_detail.quantity)}</strong>
									</div>
								</div>
								<div className="order-detail-body-right">
									Tutar:{" "}
									<strong>
										{Currency(order_detail.quantity * order_detail.amount)} ₺
									</strong>
								</div>
								<div className="order-detail-body-status">
									<StatusIcon status={order_detail.status}>
										{statusTexts[order_detail.status]}
									</StatusIcon>
								</div>
							</div>

							<OrderDetailButtons
							order_detail={order_detail}
							ChangeRoute={this.props.ChangeRoute}
							/>

						</div>

						{order_detail.content.admin_description && (
							<div className="order-info">
								<div className="admin-description">
									<svg className="icon">
										<use xlinkHref="#gs-icon-user-panel-pending"></use>
									</svg>
									<div>
									{order_detail.content.admin_description}
									</div>
								</div>
							</div>
						)}
						{order_detail.content.type == "goldbar" && (
							<div className="order-info">
								<div>
									<strong>Karakter Adı:</strong>{" "}
									{order_detail.content.character_name}
								</div>
							</div>
						)}
						{order_detail.content.type == "item" && order_detail.content.character_name && (
							<div className="order-info">
								<div>{order_detail.content.character_name}</div>
							</div>
						)}
						{order_detail.content.keys.length > 0 && (
							<div className="order-info">
								<ListKeys 
								keys={order_detail.content.keys}
								order_detail_id={order_detail.id}
								report_problem={order_detail.content.report_problem}
								ChangeRoute={this.props.ChangeRoute}
								not_show_identification_number={this.props.not_show_identification_number}
								/>
							</div>
						)}

						{(order_detail.content.is_commentable && !comment_popup) && (
								<Comment
									type={order_detail.content.type}
									comment_to_product={order_detail.content.comment_to_product}
									product_id={order_detail.content.product_id}
									order_detail_id={order_detail.id}
								/>
							)}
					</MyOrderBox>
				))}
					<Paginate
						onChange={this.ChangePage}
						total_count={order_count}
						current_page={this.state.current_page}
						per_page={15}
					/>
					</React.Fragment>
					) : (
					<div className="alert">Listelenecek sipariş bulunamadı!</div>
				)}
				{comment_popup && (
					<PopUp
						title="Yorum Yap"
						Hide={() => this.setState({ comment_popup: false })}
						className="settings-popup"
					>
						<div className="comment-pop-content">
							<div style={{textAlign:"center"}}><b>{order_details[current_order].name}</b> siparişiniz hakkında görüşleriniz nelerdir?</div>
							<br />
							<Comment
								type={order_details[current_order].content.type}
								comment_to_product={order_details[current_order].content.comment_to_product}
								product_id={order_details[current_order].content.product_id}
								order_detail_id={order_details[current_order].id}
							/>
						</div>
					</PopUp>
				)}
			</div>
		);
	}
}

export default MobileRenderer;
