import React from "react";
import PaymentMethodsCore from "./PaymentMethodsCore";
import PaymentGridListItemCreditCard from "./PaymentGridListItemCreditCard";
import PaymentGridListItemBankTransfer from "./PaymentGridListItemBankTransfer";
import PaymentGridListItemMobilePayment from "./PaymentGridListItemMobilePayment";
import PaymentGridListItemExchange from "./PaymentGridListItemExchange";
import PaymentGridListItemEpin from "./PaymentGridListItemEpin";
import PaymentGridListItemPromo from "./PaymentGridListItemPromo";
import PaymentGridFilterButton from "./PaymentGridFilterButton";
import PaymentGridListItemUSDT from "./PaymentGridListItemUSDT";

const paymentGroupToComponents = {
	credit_card: PaymentGridListItemCreditCard,
	transfer: PaymentGridListItemBankTransfer,
	mobile_payment: PaymentGridListItemMobilePayment,
	exchange: PaymentGridListItemExchange,
	epin: PaymentGridListItemEpin,
	promo: PaymentGridListItemPromo,
	usdt: PaymentGridListItemUSDT,
};

class DesktopRenderer extends PaymentMethodsCore{
	constructor(props){
		super(props);

		this.ToggleActiveGroup = this.ToggleActiveGroup.bind(this);
		this.ComponentOpen = this.ComponentOpen.bind(this);
	}

	ComponentOpen(activeTab){
		this.setState({
			activeTab
		})
	}

	ToggleActiveGroup(activeGroup){
		if(this.state.activeGroup === activeGroup){
			this.setState({
				activeGroup: null
			})
		}
		else{
			this.ChangeActiveGroup(activeGroup);
		}
	}

	render() {
		const { activeGroup, activeTab } = this.state;
		const PaymentGridListItemComponent = paymentGroupToComponents[activeGroup];
		const exchangePermission = this.props.paymentMethods.find((paymentMethod) => paymentMethod["name"].includes("Takas"));
		return (
			<section className="container">
				<div className="payment-grid">
					<div className="payment-grid-filter">
						<header>
							<h1>Ödeme Seçenekleri</h1>
							<label>Lütfen ödeme yönteminizi seçiniz</label>
						</header>
						<div className="payment-grid-filter-body">
							<PaymentGridFilterButton
								onClick={this.ToggleActiveGroup}
								group="credit_card"
								icon="credit-card"
								active={activeGroup === "credit_card" ? " active" : ""}
								label="Kredi/Banka Kartı"
							/>
							<div className="payment-grid-list" style={{maxHeight: activeGroup === "credit_card" ? '72rem' : 0}}>
							{activeGroup === "credit_card" && this.state.filteredPaymentMethods.map((paymentMethod) => (
								<PaymentGridListItemComponent
									mobile={true}
									key={paymentMethod.id}
									show={paymentMethod.id == this.state.activeTab || this.state.filteredPaymentMethods.length === 1}
									paymentMethod={paymentMethod}
									fullname={this.props.fullname}
									savePath={this.props.savePath}
									key={`payment-${paymentMethod.id}`}
									ComponentOpen={this.ComponentOpen}
									quickSelectAmounts={this.quickSelectAmounts}
								/>
							))}
							</div>
							<PaymentGridFilterButton
								onClick={this.ToggleActiveGroup}
								group="transfer"
								active={activeGroup === "transfer" ? " active" : ""}
								icon="bank-transfer"
								label="Havale/EFT"
							/>
							<div className="payment-grid-list" style={{maxHeight: activeGroup === "transfer" ? '100rem' : 0}}>
							{activeGroup === "transfer" && this.state.filteredPaymentMethods.map((paymentMethod) => (
									<PaymentGridListItemComponent
										mobile={true}
										key={paymentMethod.id}
										show={paymentMethod.id == this.state.activeTab || this.state.filteredPaymentMethods.length === 1}
										paymentMethod={paymentMethod}
										fullname={this.props.fullname}
										savePath={this.props.savePath}
										key={`payment-${paymentMethod.id}`}
										ComponentOpen={this.ComponentOpen}
									/>
								))}
							</div>
							{this.props.paymentMethods.filter((paymentMethod) => paymentMethod["group"] === "usdt").length > 0 && (
								<>
									<PaymentGridFilterButton
										onClick={this.ChangeActiveGroup}
										group="usdt"
										active={activeGroup === "usdt" ? " active" : ""}
										icon="mobile-payment"
										label="USDT"
									/>
									<div className="payment-grid-list" style={{maxHeight: activeGroup === "usdt" ? '100rem' : 0}}>
									{activeGroup === "usdt" && this.state.filteredPaymentMethods.map((paymentMethod) => (
											<PaymentGridListItemComponent
												mobile={true}
												key={paymentMethod.id}
												show={paymentMethod.id == this.state.activeTab || this.state.filteredPaymentMethods.length === 1}
												paymentMethod={paymentMethod}
												fullname={this.props.fullname}
												savePath={this.props.savePath}
												key={`payment-${paymentMethod.id}`}
												ComponentOpen={this.ComponentOpen}
												dolarRate={this.props.dolarRate}
											/>
										))}
									</div>
								</>
							)}
							
							{/*<PaymentGridFilterButton*/}
							{/*	onClick={this.ToggleActiveGroup}*/}
							{/*	group="mobile_payment"*/}
							{/*	active={activeGroup === "mobile_payment" ? " active" : ""}*/}
							{/*	icon="mobile-payment"*/}
							{/*	label="Mobil Ödeme"*/}
							{/*/>*/}
							{/* <div className="payment-grid-list" style={{maxHeight: activeGroup === "mobile_payment" ? '72rem' : 0}}>
							{activeGroup === "mobile_payment" && this.state.filteredPaymentMethods.map((paymentMethod) => (
									<PaymentGridListItemComponent
										mobile={true}
										key={paymentMethod.id}
										show={paymentMethod.id == this.state.activeTab || this.state.filteredPaymentMethods.length === 1}
										paymentMethod={paymentMethod}
										fullname={this.props.fullname}
										savePath={this.props.savePath}
										key={`payment-${paymentMethod.id}`}
									ComponentOpen={this.ComponentOpen}
									/>
								))}
							</div> */}

							{exchangePermission && (
								<>
									<PaymentGridFilterButton
										onClick={this.ToggleActiveGroup}
										group="exchange"
										active={activeGroup === "exchange" ? " active" : ""}
										icon="exchange"
										label="Takas"
									/>
									<div className="payment-grid-list" style={{maxHeight: activeGroup === "exchange"  ? '72rem' : 0}}>
									{activeGroup === "exchange" && this.state.filteredPaymentMethods.map((paymentMethod) => (
											<PaymentGridListItemComponent
												mobile={true}
												key={paymentMethod.id}
												show={paymentMethod.id == this.state.activeTab || this.state.filteredPaymentMethods.length === 1}
												paymentMethod={paymentMethod}
												fullname={this.props.fullname}
												savePath={this.props.savePath}
												key={`payment-${paymentMethod.id}`}
											ComponentOpen={this.ComponentOpen}
											/>
										))}
									</div>
								</>
							)}
							
							<PaymentGridFilterButton
								onClick={this.ToggleActiveGroup}
								group="epin"
								active={activeGroup === "epin" ? " active" : ""}
								icon="epin"
								label="GameSatış E-Pin"
							/>
							<div className="payment-grid-list" style={{maxHeight: activeGroup === "epin" ? '72rem' : 0}}>
							{activeGroup === "epin" && this.state.filteredPaymentMethods.map((paymentMethod) => (
									<PaymentGridListItemComponent
										mobile={true}
										key={paymentMethod.id}
										show={paymentMethod.id == this.state.activeTab || this.state.filteredPaymentMethods.length === 1}
										paymentMethod={paymentMethod}
										fullname={this.props.fullname}
										savePath={this.props.savePath}
										key={`payment-${paymentMethod.id}`}
									ComponentOpen={this.ComponentOpen}
									/>
								))}
							</div>
							<PaymentGridFilterButton
								onClick={this.ToggleActiveGroup}
								group="promo"
								active={activeGroup === "promo" ? " active" : ""}
								icon="promo"
								label="GameSatış Promo"
							/>
							<div className="payment-grid-list" style={{maxHeight: activeGroup === "promo" ? '72rem' : 0}}>
							{activeGroup === "promo" && this.state.filteredPaymentMethods.map((paymentMethod) => (
									<PaymentGridListItemComponent
										mobile={true}
										key={paymentMethod.id}
										show={paymentMethod.id == this.state.activeTab || this.state.filteredPaymentMethods.length === 1}
										paymentMethod={paymentMethod}
										fullname={this.props.fullname}
										savePath={this.props.savePath}
										key={`payment-${paymentMethod.id}`}
									ComponentOpen={this.ComponentOpen}
									/>
								))}
							</div>
						</div>
					</div>
				</div>
			</section>
		);
	}
}

export default DesktopRenderer;
