import React from "react";
import DecimalFieldTag from "./DecimalFieldTag";

const PriceFieldTag = (props) => {
	const wrapperClass = props.wrapperClass;
	const label = props.label;
	const inputProps = {...props};
	delete inputProps.wrapperClass;
	const id = props.id || Math.floor(Math.random() * 10E8);

	return (
		<div
			className={"price-input" + (wrapperClass ? ` ${wrapperClass}` : '')}
			id={'price-container-for-' + id}
		>
			<label htmlFor={"price-input-" + id}>{label}</label>
			<div className="price-input-text">
				<DecimalFieldTag placeholder="0,00" {...inputProps} id={"price-input-" + id} />
				<span>{props.currency ? props.currency : '₺'}</span>
			</div>
		</div>
	);
};

export default PriceFieldTag;